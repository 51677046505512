/* @import url('./fonts/fontiran.css'); */

html, body {
    font-family: IRANSansX;
    margin: 0;
    padding: 0;
    border-radius: 0;
    overflow-x: hidden!important;
}

h1, h2, h3, h4, h5, h6, input, textarea, p, span, div, form, table, tr, th, td {
    font-family: IRANSansX;
}

.iransans {
    font-family: IRANSansX !important;
}

.iransans-fa-num {
    font-family: IRANSansXFaNum !important;
    font-weight: 300;
}

.max-width-100 img {
    max-width: 100% !important;
    height: auto !important;
}

.overflow-hidden {
    overflow: hidden;
}

.no-underline {
    text-decoration: none !important;
}

.post {
    text-decoration: none !important;

}
